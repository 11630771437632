import React from 'react';
import SEO from '../components/seo';

const NotFoundPage = () => (
    <div className='main'>
        <div className='row'>
            <div className='col-md-8 offset-md-2'>
                <SEO title='Error Page' />
                <h1>Unexpected Error</h1>
                <p>We apologize, an error was encountered while attempting to process your request.</p>
                <p>
                    <a href='https://www.palottery.state.pa.us/' rel='noopener noreferrer'>
                        Click here to visit the PA&nbsp;Lottery home page
                    </a>
                    .
                </p>
                <p>
                    <strong>Pennsylvania Lottery</strong>
                </p>
            </div>
        </div>
    </div>
);

export default NotFoundPage;
